:root {
  --fonte: 'Montserrat', sans-serif;
  --branco: #FFFFFF;
  --preto: #000000;
  --cinza: #E2E2E2;
  
  --gradiente: linear-gradient(
    180deg, rgba(9,35,175,1) 0%, 
    rgba(0,0,0,1) 100%;

    
  );
  
}

body {
  margin: 0;
  font-family: var(--fonte);
  background-color: var(--branco);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('../public/imagens/backgroundMerenda.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;   
}

.swal-custom-radio {
  display: flex;
  flex-direction: column; 
  gap: 10px; 
  align-items: flex-start; 
  font-size: 14px;
}