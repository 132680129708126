*{
    font-family: "Imprima", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.container{
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.nameprefeitura{
    color: black;
    font-size: 18px;
    font-weight: bold;
    max-width: 300px;
    display: flex;
    text-align: center;
}

.tituloMerenda{
    color: #008ba3;
    font-size: 28px;
    text-align: center;
    font-weight: bold;
}

.containerImg{
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    margin-top: 40px;
}

.cardContainer{
    align-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;  
    padding-bottom: 50px;
}

.img{
    width: 25%;  
    
}

.iconCaixa{
    width: 35px;
    width: 35px;
}

@media (max-width:638px){
    .img{
        width: 50%;    
    }

}