.footer{
   
    width: 100%;
    background-color:rgb(149, 228, 255);
    display: flex;
    flex-direction: column;
    margin-top: auto
  }
  .containersubtitulo{
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
  }
  .subtitulo{
    display: flex;
    font-weight: 700;
    margin-left: 5px;
    margin-right: 5px;
  }
  .spaces{
    height: 2px;
    width: 100px;
    background-color: black;
  }
  .textfooter{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  
  
  @media (max-width:668px){
  
    .textfooter{
      margin-left: 10px;
    }
    
  
  }