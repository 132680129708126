.containerButton{

    height: 152px;/*tamanho do botao*/
    width: 152px;
    background-color:rgba(0, 191, 252, 0.5);
    border-radius: 15px; /*borda*/
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
    
}


.circleVazio{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid grey;
    margin-top: 4px;
    align-items: none;
    justify-content:none;
    margin-left: 110px;
    background-color: white;

}

.capa{
    width: 100px;
    height: 100px;
    justify-content: center;
    align-items: center;
    display: flex;  
    
}

.title{

    text-decoration: none;
    color: black;
    font-size: 18px;
    margin: 0px; 
    
}
